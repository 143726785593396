<template>
	<!-- 个人中心页面 -->
	<div>
		<div class="maninfo" v-if="orderinfoactive==1">
			<div class="p-20 bg-f flex m-b-10">
				<div class="b-r-dcd m-r-30">
					<div class="w-240 tc  m-r-20">
						<img class="w-80 h-80 rad50 m-b-10" :src="$store.state.app.iconpath!=''?$store.state.app.iconpath:require('../../assets/img/userimg.png')">
						<h3 class="m-b-8 f-16  bold">{{$store.state.app.login.cardname}}</h3>
						<p class="c-6 ell">{{companyname}}</p>
					</div>
				</div>
				
				<div  class="flex s-b w100 p-r-30">
					<div class="flex" @click="gotoSetmeal(0)">
						<div class="m-r-10 flex"><img class="w-70 h-70 m-a" src="../../assets/img/m-7.png"/> </div>
						<div class="flex-c s-c">
							<div class="f-14 bold m-b-10"> {{$store.state.app.Operational.member.Purchasinghistory}}</div>
							<div> {{$store.state.app.Operational.member.Purchased}} <span class="c-ff8 bold f-24">{{getnumberArr.buyqty||0}}</span>  件 </div>
							<div class="c-6 m-t-10 cursor">查看明细</div>
						</div>
					</div>
					<div class="flex" @click="gotoSetmeal(1)">
						<div class="m-r-10 flex"><img class="w-70 h-70 m-a" src="../../assets/img/m-9.png"/> </div>
						<div class="flex-c s-c">
							<div class="f-14 bold m-b-10"> {{$store.state.app.Operational.member.Purchasing}}</div>
							<div> {{$store.state.app.Operational.member.Purchasing}} <span class="c-ff8 bold f-24">{{getnumberArr.cartnumber||0}}</span>  件 </div>
							<div class="c-6 m-t-10 cursor">查看明细</div>
						</div>
					</div>
					<div class="flex" @click="gotoSetmeal(2)">
						<div class="m-r-10 flex"><img class="w-70 h-70 m-a" src="../../assets/img/m-8.png"/> </div>
						<div class="flex-c s-c">
							<div class="f-14 bold m-b-10">我的收藏</div>
							<div> 已收藏 <span class="c-ff8 bold f-24">{{getnumberArr.favoritescount||0}}</span> 件商品 </div>
							<div class="c-6 m-t-10 cursor">查看明细</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div class="bg-f p-0-20">
				<h3 class="bg-f  l-h-65 f-18 bold" ><i class="shu m-r-5 t-4"></i>{{ $store.state.app.Operational.member.Myorderform}}</h3>
				<el-tabs v-model="active" @tab-click="maningotag">
				    <el-tab-pane label="全部订单" name="0"></el-tab-pane>
				    <el-tab-pane label="待付款" name="1"></el-tab-pane>
					<el-tab-pane label="待审核" name="11"></el-tab-pane>
				    <el-tab-pane label="待发货" name="2"></el-tab-pane>
					<el-tab-pane label="部分发货" name="55"></el-tab-pane>
				    <el-tab-pane label="已发货" name="3"></el-tab-pane>
					<el-tab-pane label="已完成" name="4"></el-tab-pane>
				</el-tabs>
				
				<div class="p-b-20">
					<div class="bg-f8 rad-8 p-10 m-b-10 flex relative" v-for="(item,index) in myorderlist" :key="index">
						<div class="flex ">
							<div class="m-r-15 ">
								<div class="w-100 h-100">
									<img class="w-100 h-100" v-if="item.imageurl" :src="item.imageurl" onerror="javascript:this.src='../../assets/img/mr.png';">
									<img class="w-100 h-100" v-if="!item.imageurl" src="../../assets/img/mr.png" alt="">
								</div>
								<img v-if="$store.state.app.login.cardid!=item.cardid" class="w-35 h-35 absolute t-0 l-0" src="../../assets/img/daike.png" alt="">
							</div>
							<div>
								<div class=" l-h-25">
									<p>订单编号：{{item.tradeid}}</p>
									<p>下单时间：{{item.tradedate}}</p>
									<p class=""><i class="iconfont" :class="flagicon[item.flag]"></i> {{orderactivename[item.flag]}} <span v-if="item.paytypeid">| </span>{{paytype[item.paytypeid]}} </p>
									<p v-show="item.flag==11||item.flag==12||item.flag==13"> {{activetishi[2]}}</p>
									<p v-show="item.flag==2"> {{activetishi[1]}}</p>
									<p v-show="item.flag==3"> {{activetishi[4]}}</p>
									<p v-show="item.flag==4"> {{activetishi[0]}}</p>
									<p v-show="item.flag==-2"> {{activetishi[5]}}</p>
								</div>
							</div>
						</div>
						
						<div class="detail  wcOrder p-r-10 ">
							<p class="c-6 cursor  " @click="lookinfo(item)">查看详情</p>
							<!-- <p class="B14E cursor m-b-15" v-show="item.flag==4" @click="moreBay(item.tradeid)">再次购买</p> -->
							<p class="--color cursor m-b-15 m-t-15" @click="wcOrder(item.tradeid)" v-show="item.flag==3">确认收货</p>
							<p class="c-9 cursor m-t-15" @click="Logistics(item)" v-show="item.flag==3||item.flag==4||item.flag==55">
								物流信息</p>
							<p class="--color cursor m-b-15 m-t-15"  @click="seeinvoice(item)" v-show="item.invoiceflag == 100">
								查看发票</p>
								
							<!-- 立即支付 -->
							<p class="--color cursor  m-t-15" @click="showpayment(item.tradeid)" v-show="item.flag==1">
								<span v-if=" $store.state.app.Operational">{{ $store.state.app.Operational.member.goTopaid}}</span>
							</p>
								
						</div>
	
					</div>
					
					<div class=" bg-f tc min-h-290 relative" v-if="!myorderlist">
						<div class="w-160 jz h-180">
							<img class="w-160 " src="../../assets/img/sc-1.png">
							<p class="c-9 tc">暂无数据</p>
						</div>
					</div>
					
					<div id="Pagination"> 
						<el-pagination background layout="prev, pager, next ,total,jumper" :page-size="pagesize" @current-change="memberBackhandleCurrentChange" :current-page="pageno" :total="orderDotal">
						</el-pagination>
					</div>
					
				</div>
			</div>
		</div>
		<invoices v-if="orderinfoactive == 4" :detail="detail" @ret="orderinfoactive = 1" />
		<orderinfo v-if="orderinfoactive==2" :detail="detail" @ret="orderinfoactive=1"/>
		<logistics v-if="orderinfoactive==3" :detail="detail"/>
		<paytype :paytypedialog="paytypedialog" :tradeid="tradeid"/>
	</div>
	
</template>

<script>
	var companyname = sessionStorage.getItem('companyname');
	var ismainadmin = sessionStorage.getItem('ismainadmin');
	var cardname = sessionStorage.getItem('cardname');
	var orgno = sessionStorage.getItem('orgno');
	var openid = sessionStorage.getItem('openid');
	var erpcustno = sessionStorage.getItem('erpcustno');
	// var cardid = sessionStorage.getItem('cardid');
	// var usernames = sessionStorage.getItem('usernames');
	 import orderinfo from './orderinfo.vue'
	 import logistics from './logistics.vue'
	  import paytype from '../../components/paytype.vue'
	  import invoices from './invoices.vue';
	export default{
		components:{orderinfo,logistics,paytype,invoices},
		data() {
			companyname = sessionStorage.getItem('companyname');
			ismainadmin = sessionStorage.getItem('ismainadmin');
			cardname = sessionStorage.getItem('cardname');
			orgno = sessionStorage.getItem('orgno');
			openid = sessionStorage.getItem('openid');
			erpcustno = sessionStorage.getItem('erpcustno');
			return {
					maningo: [
						{imgu:'b2b-quanbu',titles: "全部"},
						{imgu:'b2b-daifukuan',titles:""}, 
						{imgu:'b2b-daifahuo',titles:""}, 
						{imgu:'b2b-yifahuo',titles: ""}, 
						{imgu:'b2b-yiwancheng',titles: "已完成"},
					],
					flagicon:{1:'b2b-dengdaizhuangtai c-ffa ', 2:'b2b-dengdaizhuangtai c-ffa ',4:'b2b-tongguo c-4BBE12',5:'b2b-weitongguo c-FF5E5E'},
					// 订单状态 1=待付款、2=已付款待发货、3=已付款已发货、4=交易完成 6=退货中,7=已退货 -2 已作废 55=部分发货
					
					active: '0',
					erpcustno: erpcustno,//公司编码
					// type: '-1,-2,-3,-4,1,2,3,4,5,6,7,9,10,11,12,13,20,30', //订单状态，（11）管理员审核（12）销售员审核（-2） 审核失败（13）待分公司审核（2）待发货（3）已发货（4）交易完成
					pageno: 1,
					pagesize: 10,
					orderDotal:0,
					
					indexcount: '',//审批次数
					myorderlist: [],//我的订单,
					mynewsArr: [],//我的消息
					getnumberArr: [],//
					
					activetishi: [
						'您的采购订单已经配送完成，感谢您的采购！',
						'您的采购订单已经提交成功,等待发货！',
						'您的采购订单正在等待审核！', 
						'您的采购订单正在等待审核！',
						'您的采购订单已经发货，请注意查收！',
						'商家已取消订单'
					],
					flags: {
						CF: "首营客户",
						XR: '退货收货',
						XC: '发货',
						XD: '订单分公司已审核',
						XH: '采购模板分公司已审核',
						XS: '退货单受理已审核',
						HZ: '结算单已审核'
					},
					// 消息名称
					newname: {
						CF: "首营客户",
						XR: '退货编号',
						XC: '订单编号',
						XD: '订单编号',
						XH: '模板名称',
						XS: '退货编号',
						HZ: '结算单已审核'
					},
		
					// 订单详情数据
					isnews: false,					//是否从消息模块进来
					isreturnprv: '',					//消息模块进来的tab id
					orderinfoactive: 1,					//是否显示订单详情开关

					remark: '', 
					ordertype: 0, 
					ismainadmin: ismainadmin, 
					tradeId: '', 
					cardname: cardname,
					companyname: sessionStorage.getItem('companyname')||'', 
		
			
					wlactive: 1, 
					ifwl: false, 
					flag: '', 
					paytype: ['货到付款', '微信支付', '储值卡', '支付宝', '银行转账', '信用付', "现金支付",'其它支付', "线下支付",'医保混合支付'], 
				
					downloadurl: '',
					orderactivename:'',
					detail:'',
					paytypedialog:false,//支付弹窗
					tradeid:'',//订单编号
					
					
			}
		},

		
		created: function created() {
			this.orderlist(); // this.mynewslist()
			this.getnumber();
			this.isFollow()
			
		},
		methods: {
			isFollow(){
				this.orderactivename={
					// '-1':'已删除',
					'-2': '已作废',
					// '-3': '销售员审核失败',
					// '-4': '分公司审核失败',
					1: this.$store.state.app.Operational?this.$store.state.app.Operational.member.Tobepaid:'',
					2: this.$store.state.app.Operational?this.$store.state.app.Operational.member.Tobedelivered:'',
					3: this.$store.state.app.Operational?this.$store.state.app.Operational.member.Delivered:'',
					4: this.$store.state.app.Operational?this.$store.state.app.Operational.member.Transactioncompleted:'',
					5:this.$store.state.app.Operational?this.$store.state.app.Operational.member.Transactionclosure:'',
					11:'待管理员审核',
					12:'待销售员审核', 
					13:'待分公司审核',
					20:'待分公司审核',
					30:'待分公司审核',
					55:'部分发货'
				}
			},
			
			// 点击采购
			gotoSetmeal: function gotoSetmeal(index) {
				switch (index) {
					case 0:
						// this.$parent.menuitemAactive='2-1'
						// this.$parent.defaultactive='2-1'
						document.documentElement.scrollTop=300
						break;
					case 1:
						this.$router.push({path:'/cart'})
						break;
					case 2:
						this.$parent.menuitemAactive='1-4'
						this.$parent.defaultactive='1-4'
						break;
				
				}
			},
			
			
			// 点我的订单maningotag
			maningotag: function maningotag(active) {
				// this.active = active;
				console.log(this.active)
				this.flag = this.active
				this.pageno = 1;
				switch (this.active) {
					case '0':this.flag = '';break;
					case '1':this.flag = '1';break;
					case '2':this.flag = '2';break;
					case '3':this.flag = '3';break;
					case '4':this.flag = '4';break;
					case '55':this.flag = '55';break;
				}
				this.orderlist();  
			},
			
			
			// 查询订单列表 POST /trade/list
			orderlist: function orderlist() {
		
				var data = {
					flags: this.flag,
					pageno: this.pageno,
					pagesize: this.pagesize,
					orgno: orgno
				};
				
				this.requset('/trade/list', data).then(res=>{
						if (res.status == 'success') {
							var result = res.data.rows;
							for (var i in result) {result[i]['noticetype'] = 'XD';}
							this.myorderlist = result;
						} else {
							this.authorization(res);
						}
						if( res.data)this.orderDotal = res.data.total;
					});
				
			},
			
			
		
			
			
			// 获取各种数量
			getnumber: function getnumber() {
				var data = {
					openid: openid,
					pageno: this.pageno,
					pagesize: 10,
				};
				this.requset('/b2bmembercard/numbers', data).then(data=>{
						if (data.status == 'success')this.getnumberArr = data.data;
				});
			},
			
			
			seeinvoice:function seeinvoice(item){
				document.documentElement.scrollTop = 0;
				this.detail=item
				this.orderinfoactive = 4;
			},
			
			
			// ---查看订单----------------------------------------------------------
			lookinfo: function lookinfo(item) {
				this.detail=item
				console.log(item);
				this.orderinfoactive = 2;
			},
			
			memberBackhandleCurrentChange(tab) {
				this.pageno = tab;
				this.orderlist();
			},
			

			
			
			// 完成订单
			wcOrder(tradeId) {
				this.wcOrders(tradeId,res=>{
					this.orderlist();
				})
			},
			
			// 再次购买
			moreBay: function moreBay(tradeid) {
				var _this3 = this;
				// 请求使用方法
				var Url = '/nb2btrade/repurchase.kb';
				var data = {tradeId: tradeid,};
				this.requset(Url, data).then(function(data) {
						_this3.$notify({title: '成功',message: '商品已添加到购物车，您可以到购物车购买',type: 'success'});
					}, function(err) {
						_this3.$notify.error({title: '错误',message: err.message});
					});
				
			},
			
			
			//弹窗支付方式
			showpayment: function showpayment(tradeid) {
				this.tradeid=tradeid
				this.paytypedialog=true
				
				// credit(this.erpcustno, function(res) {
				// 	headerTop.creditdata = res; //获取信用额度
				// 	headerTop.paytypedialog = true;
				// 	headerTop.payTradeid = tradeid;
				// });
			},
			
			
			// 物流信息
			Logistics: function Logistics(item) {
				this.detail=item
				this.orderinfoactive = 3;

			},

			// 物流返回
			wiback: function wiback() {
				this.Logisticsactive = false;
				this.ifwl = false;
				this.orderinfoactive = !this.orderinfoactive;
				this.logisticdetail = [];
				this.traces = [];
				this.wlactive = 1;
				this.logisticcode = '';
			}
		}
	}
</script>

<style>
	.el-tabs__content {display: none;}
	.el-tabs__item:hover{color: unset !important;}
</style>
